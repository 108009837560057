const INITIAL_STATE = {
  token: {},
};

const TokenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'setTokenAction':
      return { ...state, token: action.payload.setTokenAction };
    default:
      return state;
  }
};

export default TokenReducer;
