const INITIAL_STATE = {
  imputData: [],
  selected: 0,
  from: '2021-01-01',
  to: '2021-01-09',
};

const StatisticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'setImputDataAction':
      return { ...state, imputData: action.payload };
    case 'addInputDataAction':
      return {
        ...state,
        imputData: [...state.imputData, ...action.payload],
      };
    case 'selectedAction':
      return { ...state, selected: action.payload };
    case 'setFromAction':
      return { ...state, from: action.payload };
    case 'setToAction':
      return { ...state, to: action.payload };
    default:
      return state;
  }
};

export default StatisticsReducer;
