
export const theme = {
  "primary-color": "#273247",
  "secondary-color": "#1f8efa",
  "tercary-color": "#ffffff",
  "quadrary-color": "#4f4f4f",
  "pentary-color": "#ffcc33",
  "hexary-color": "#c4c4c4",
  "septimary-color": "#fa461f",
  "octamary-color": "#cc0066",
  
  "primary-font-family": "Montserrat",
  "secondary-font-family": "Roboto",

  "lvl_6_color": "#cc0066",
  "lvl_5_color": "#ffcc33",
  "lvl_4_color": "#006666",
  "lvl_3_color": "#8bbf18",
  "lvl_2_color": "#9470b7",
  "lvl_1_color": "#00b7e5",

  "mobile-width": 993,
}