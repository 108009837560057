const INITIAL_STATE = {
  userData: {
    profile: {
      name: '',
      img: '',
      level: 1,
      title: '',
    },
    statisticsData: {
      timeData: {
        from: '',
        to: '',
      },

      statisticsRecords: [],
    },
  },
  selectedData: {
    profile: {
      name: '',
      img: '',
      level: 1,
      title: '',
    },
    statisticsData: {
      timeData: {
        from: '',
        to: '',
      },

      statisticsRecords: [],
    },
  },
};

const PrivateStatisticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'setUserDataAction':
      return { ...state, userData: action.payload };
    case 'setSelectedDatadAction':
      return { ...state, selectedData: action.payload };

    default:
      return state;
  }
};

export default PrivateStatisticsReducer;
