const INITIAL_STATE = {
  is_hamburger_open: false,
};

const MobileMenuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'menuAction_hamburger':
      return { ...state, is_hamburger_open: action.payload };
    default:
      return state;
  }
};
export default MobileMenuReducer;
