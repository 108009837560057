const INITIAL_STATE = {
  unreadMessage: {
    personal: 0,
    news: 0,
    garden: 0,
    gardenMessages: [],
  },
};

const UnreadMessageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'setUnreadMessageAction':
      return { ...state, unreadMessage: action.payload };
    default:
      return state;
  }
};
export default UnreadMessageReducer;
