const INITIAL_STATE = {
  is_selector_menu_open: true,
  is_help_menu_open: true,
};

const MenuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'menuAction_selector':
      return { ...state, is_selector_menu_open: action.payload };
    case 'menuAction_help':
      return { ...state, is_help_menu_open: action.payload };
    default:
      return state;
  }
};

export default MenuReducer;
