import React from 'react';
import ReactDOM from 'react-dom';

/// REACT-REDUX
import { Provider } from 'react-redux';
/// REDUX
import { createStore } from 'redux';
/// REDUCERS
import Reducers from './Model/Reducers';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import ComponentLoader from './Model/ComponentLoader';

//import reportWebVitals from './reportWebVitals';
import './Assets/Style/index.css';
import './Assets/Style/react-inputs-validation.css';

import 'react-quill/dist/quill.snow.css';
import './Assets/Style/custome_qurill.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
/// GOOGLE ANALYTICS
import ReactGA from 'react-ga';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'systemState',
    'mobileMenuState',
    'cartBadgeNumberState',
    'unreadMessageState',
    'statisticsState',
    'privateStatisticsState',
    'assumeState',
    'colleguesState',
  ],
};

const persistedReducer = persistReducer(persistConfig, Reducers);
export let store = createStore(persistedReducer);
let persistor = persistStore(store);

/*  
const Test = React.lazy(() => {
  return new Promise(resolve => setTimeout(resolve, 5 * 1000)).then(() =>import("./Model/Test"));
}); 
*/

const System = React.lazy(() => import('./Model/System'));

ReactGA.initialize(process.env.REACT_APP_ENV === 'tassi' ? 'G-SXDRRM7KM6' : 'G-F3B8X44LG1', {
  debug: false,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ComponentLoader
            styles={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <System />
          </ComponentLoader>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(Test)
//reportWebVitals();
