import React, { Suspense } from 'react';

function ComponentLoader({
  children,
  styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25%',
  },
  loading = 'Töltődik...',
}) {
  return <Suspense fallback={<div style={{ ...styles }}>{loading}</div>}>{children}</Suspense>;
}

export default ComponentLoader;
