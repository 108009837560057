const INITIAL_STATE = {
  dimension: {
    height: window.innerHeight,
    width: window.innerWidth,
    loading: false,
  },
  scroll: {
    x: window.scrollX,
    y: window.scrollY,
  },
  loading: false,
};

const SystemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'dimensionAction':
      return { ...state, dimension: action.payload.dimension };
    case 'scrollAction':
      return { ...state, scroll: action.payload.scroll };
    case 'loadAction':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default SystemReducer;
