const INITIAL_STATE = { assumes: [] };

const AssumeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'setAssumes':
      return { ...state, assumes: action.payload };

    default:
      return state;
  }
};

export default AssumeReducer;
