import { combineReducers } from 'redux';

import SystemReducer from './SystemReducer';
import UserReducer from './UserReducer';
import TokenReducer from './TokenReducer';
import MenuReducer from './MenuReducer';
import MobileMenuReducer from './MobileMenuReducer';
import ActiveThemeReducer from './ActiveThemeReducer';
import UnreadMessageReducer from './UnreadMessageReducer';
import AssumeReducer from './AssumeReducer';
import StatisticsReducer from './StatisticsReducer';
import PrivateStatisticsReducer from './PrivateStatisticsReducer';
import ColleguesReducer from './ColleguesReducer';

export default combineReducers({
  systemState: SystemReducer,
  userState: UserReducer,
  tokenState: TokenReducer,
  menuState: MenuReducer,
  mobileMenuState: MobileMenuReducer,
  activeThemeState: ActiveThemeReducer,
  unreadMessageState: UnreadMessageReducer,
  assumeState: AssumeReducer,
  statisticsState: StatisticsReducer,
  privateStatisticsState: PrivateStatisticsReducer,
  colleguesState: ColleguesReducer,
});
